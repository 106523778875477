import {
  Badge,
  Button,
  Card,
  Col,
  Image,
  Row,
  Space,
  Tag,
  Tooltip,
} from 'antd';
import clsx from 'clsx';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { useMedia } from 'react-use';
import { AudioIcon } from '../../../../assets/svg';
import {
  BREAKPOINTS,
  DATE_TIME_WITH_AT,
  GA_EVENT,
  GA_LABEL,
  INS_STATUS_CLASSNAME,
  INS_STATUS_KEYS,
  INS_STATUS_LABEL,
} from '../../../../common/constants';
import { Event } from '../../../../common/trackEvents';
import AudioPlayerModal from '../../../../components/AudioPlayerModal';
import EllipsisText from '../../../../components/EllipsisText';
import AddResponseDrawer from './AddResponseDrawer';
import AddResponseModal from './AddResponseModal';

function ResponseDetailCard({
  responseDetail,
  instructionData,
  index,
  count,
  token,
  refetch,
}) {
  const [showAudioModal, setShowAudioModal] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [previewImageKey, setPreviewImageKey] = useState('');
  const [showResponseModal, setShowResponseModal] = useState(false);
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);

  return (
    <div
      className={clsx(
        'response-detail',
        responseDetail?.status === INS_STATUS_KEYS?.RESPONDED &&
          'd-flex flex-vertical justify-end align-end',
        count - 1 === index && !isDesktopViewport && 'mb-80',
      )}
    >
      <div className="name-and-time d-flex">
        <Tooltip
          title={
            responseDetail?.responder?.name ||
            responseDetail?.rejector?.name ||
            responseDetail?.creator?.name
          }
        >
          <div className="ellipsis-text">
            {responseDetail?.responder?.name ||
              responseDetail?.rejector?.name ||
              responseDetail?.creator?.name}
          </div>
        </Tooltip>
        <Badge status="default" className="ml-5 mr-5" color="#676C77" />
        {moment(responseDetail?.createdAt)
          .tz(instructionData?.project?.timeZone)
          .format(DATE_TIME_WITH_AT)}
      </div>
      <Card
        className={clsx(
          'response-detail-card',
          responseDetail?.status === INS_STATUS_KEYS?.RESPONDED &&
            'grey-background',
        )}
      >
        <div className="instruction-tab-details">
          <Tag
            className={`${
              INS_STATUS_CLASSNAME[responseDetail?.status]
            } status-tag mb-12`}
          >
            {INS_STATUS_LABEL[responseDetail?.status]}
          </Tag>
          <div>
            <EllipsisText text={responseDetail?.remark || '-'} />
          </div>
          <Row gutter={18} align="middle" justify="space-between">
            {responseDetail?.photos?.length > 0 && (
              <Col>
                <Space direction="vertical" size={12} className="mt-12">
                  <div>
                    <Image.PreviewGroup
                      preview={{
                        visible:
                          responseDetail?.revision === previewImageKey &&
                          isVisible,
                        onVisibleChange: (visible) => {
                          setIsVisible(visible);
                          if (visible) {
                            Event(GA_EVENT.OPENED_IMAGE_VIEWER, {
                              label: GA_LABEL.OPENED_IMAGE_VIEWER,
                              // eslint-disable-next-line no-undef
                              pathname: window?.location?.href,
                            });
                          }
                        },
                      }}
                    >
                      <Space className="image-preview-wrapper issue-images">
                        {React.Children.map(responseDetail?.photos, (photo) => (
                          <Image
                            src={photo}
                            alt="logo"
                            height="64px"
                            width="64px"
                            onClick={() =>
                              setPreviewImageKey(responseDetail?.revision)
                            }
                          />
                        ))}
                        {responseDetail?.photos?.length > 1 && (
                          <div
                            className="extra-count pointer"
                            onClick={() => {
                              setIsVisible(true);
                              setPreviewImageKey(responseDetail?.revision);
                            }}
                          >
                            {`+${responseDetail?.photos?.length - 1}`}
                          </div>
                        )}
                      </Space>
                    </Image.PreviewGroup>
                  </div>
                </Space>
              </Col>
            )}
            {responseDetail?.audios?.length > 0 && (
              <Col>
                <Space direction="vertical" size={12}>
                  <div>
                    <div
                      className="audio-player-wrapper issue-audio"
                      onClick={() => {
                        setShowAudioModal(true);
                        Event(GA_EVENT.DOWNLOAD_AUDIO, {
                          label: GA_LABEL.DOWNLOAD_AUDIO,
                          // eslint-disable-next-line no-undef
                          pathname: window?.location?.href,
                        });
                      }}
                    >
                      <AudioIcon />
                      {responseDetail?.audios?.length > 1 && (
                        <div className="extra-count">{`+${
                          responseDetail?.audios?.length - 1
                        }`}</div>
                      )}
                    </div>
                  </div>
                </Space>
              </Col>
            )}
          </Row>
          {responseDetail?.audios?.length > 0 && (
            <AudioPlayerModal
              audios={responseDetail?.audios}
              showModal={showAudioModal}
              setShowModal={setShowAudioModal}
            />
          )}
        </div>
        {responseDetail?.status === INS_STATUS_KEYS?.REJECTED &&
          instructionData?.status === INS_STATUS_KEYS?.REJECTED &&
          count - 1 === index && (
            <Button
              shape="round"
              type="primary"
              className="report-button b-0 width-percent-100 justify-center mt-16"
              onClick={() => setShowResponseModal(true)}
            >
              Add Response
            </Button>
          )}
        {showResponseModal && isDesktopViewport ? (
          <AddResponseModal
            showModal={showResponseModal}
            setShowModal={setShowResponseModal}
            instructionData={instructionData}
            token={token}
            refetch={refetch}
          />
        ) : (
          <AddResponseDrawer
            open={showResponseModal}
            instructionData={instructionData}
            onClose={() => setShowResponseModal(false)}
            token={token}
            refetch={refetch}
          />
        )}
      </Card>
    </div>
  );
}

export default ResponseDetailCard;
