import { Button, Collapse } from 'antd';
import React, { useState } from 'react';
import { useMedia } from 'react-use';
import { FilterWhite } from '../assets/svg';
import { BREAKPOINTS } from '../common/constants';
import SearchComponent from './SearchComponent';

const CollapsibleFilterWrapper = ({
  searchProps = {},
  filterButtonProps = {
    className: 'filter-btn',
    shape: 'round',
    icon: <FilterWhite />,
  },
  className,
  items,
}) => {
  const { onClick: onFilterButtonClick } = filterButtonProps;
  const { className: searchClassName } = searchProps;
  const [activeKey, setActiveKey] = useState([]);
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const handleClick = () => {
    setActiveKey(activeKey?.length ? [] : ['filter']);
    if (onFilterButtonClick) onFilterButtonClick();
  };
  return (
    <div className={`collapsible-filter-wrapper ${className || ''}`}>
      {!isDesktopViewport && (
        <div className="d-flex mb-10">
          <SearchComponent
            {...searchProps}
            className={`mr-10 ${searchClassName}`}
          />
          <Button {...filterButtonProps} onClick={handleClick} />
        </div>
      )}
      <Collapse
        activeKey={isDesktopViewport ? ['filter'] : activeKey}
        items={items}
      />
    </div>
  );
};

export default CollapsibleFilterWrapper;
