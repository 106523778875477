import { dropRight, map, reverse } from 'lodash';
import React from 'react';
import { useMedia } from 'react-use';
import { BREAKPOINTS, INS_STATUS_KEYS } from '../../../../common/constants';
import AddResponseCard from './AddResponseCard';
import ResponseDetailCard from './ResponseDetailCard';

function IssueActivity({ instructionData, token, refetch }) {
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const filteredData = reverse(
    dropRight(instructionData?.instructionIssueHistories),
  );
  return (
    <div className="issue-activity">
      {instructionData?.status === INS_STATUS_KEYS?.RAISED &&
        isDesktopViewport && (
          <div className="d-flex justify-end">
            <AddResponseCard
              instructionData={instructionData}
              token={token}
              refetch={refetch}
            />
          </div>
        )}
      <div>
        {map(filteredData, (value, index) => {
          return (
            <ResponseDetailCard
              responseDetail={value}
              instructionData={instructionData}
              index={index}
              count={filteredData?.length}
              token={token}
              refetch={refetch}
            />
          );
        })}
      </div>
    </div>
  );
}

export default IssueActivity;
