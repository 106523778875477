import { useQuery } from '@apollo/client';
import { Avatar, Dropdown, Layout } from 'antd';
import * as eva from 'eva-icons';
import Gleap from 'gleap';
import { filter } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import alternateLogo from '../../../assets/images/alternateLogo.png';
import { CloseIcon, MenuBar } from '../../../assets/svg';
import {
  ACCESS_TYPE,
  ALLOWED_ACTION_KEYS,
  MODULES,
  ROUTES,
  SYSTEM_ROLES,
} from '../../../common/constants';
import useRouter from '../../../common/useRouter';
import CanPerform from '../../../components/CanPerform';
import HasAccess from '../../../components/HasAccess';
import {
  GET_INSTRUCTION_LIST,
  GET_USER_EQC_APPROVAL_LOGS_COUNT,
} from '../../../modules/todo/graphql/Queries';
import { GET_PROFILE } from '../../../modules/users/graphql/Queries';
import UserProfile from './UserProfile';

const { Header } = Layout;
const initialTodoFilter = {
  skip: 0,
  limit: 10,
  sortBy: { field: 'updatedAt', order: 'DESC' },
  isRfi: false,
};
const initialProjectFilter = {
  skip: 0,
  limit: 10,
  sortBy: { field: 'createdAt', order: 'DESC' },
};
const MobileHeader = () => {
  const [visible, setVisible] = useState(false);
  const { data } = useQuery(GET_USER_EQC_APPROVAL_LOGS_COUNT, {
    variables: { filter: initialTodoFilter },
    fetchPolicy: 'network-only',
  });
  const { data: instructionData } = useQuery(GET_INSTRUCTION_LIST, {
    variables: { filter: initialProjectFilter },
    fetchPolicy: 'network-only',
  });
  const {
    location: { pathname },
  } = useRouter();

  useEffect(() => {
    eva.replace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line no-undef
  window.onbeforeunload = closeIt;

  function closeIt() {
    Gleap.clearIdentity();
  }

  const { data: userData } = useQuery(GET_PROFILE, {
    onCompleted(res) {
      Gleap.identify(res?.getLoggedInUser?.id, {
        name: res?.getLoggedInUser?.name,
        email: res?.getLoggedInUser?.email,
        phone: res?.getLoggedInUser?.phoneNo,
      });
    },
  });
  const isProjectAdmin = !!filter(
    userData?.getLoggedInUser?.projectUsers,
    (user) => user?.roles === SYSTEM_ROLES.PROJECT_ADMIN,
  ).length;
  const isInspector = !!filter(
    userData?.getLoggedInUser?.projectUsers,
    (user) => user?.roles === SYSTEM_ROLES.INSPECTOR,
  ).length;
  const hasInspectionAccess = !!HasAccess({ type: ACCESS_TYPE.INSPECTION });
  const items = [
    ...(hasInspectionAccess &&
    CanPerform({
      action: ALLOWED_ACTION_KEYS.VIEW_DASHBOARD_PAGE,
    })
      ? [
          {
            label: (
              <Link to={ROUTES.MAIN} className="d-flex align-center">
                {MODULES.DASHBOARD}
              </Link>
            ),
            key: ROUTES.MAIN,
          },
        ]
      : []),
    ...(CanPerform({
      action: ALLOWED_ACTION_KEYS.VIEW_PROJECTS_PAGE,
    })
      ? [
          {
            label: (
              <Link to={ROUTES.PROJECTS} className="d-flex align-center">
                {MODULES.PROJECTS}
              </Link>
            ),
            key: ROUTES.PROJECTS,
          },
        ]
      : []),
    ...(CanPerform({ action: ALLOWED_ACTION_KEYS.VIEW_SETUP_PAGE })
      ? [
          {
            label: (
              <Link
                to={`${ROUTES.SETUP}${ROUTES.USERS}`}
                className="d-flex align-center"
              >
                {MODULES.SETUP}
              </Link>
            ),
            key: ROUTES.SETUP,
          },
        ]
      : []),
    ...(CanPerform({ action: ALLOWED_ACTION_KEYS.VIEW_LOGS_PAGE })
      ? [
          {
            label: (
              <Link to={ROUTES.LOGS} className="d-flex align-center">
                {MODULES.LOGS}
              </Link>
            ),
            key: ROUTES.LOGS,
          },
        ]
      : []),
    ...(hasInspectionAccess &&
    (CanPerform({
      action: ALLOWED_ACTION_KEYS.VIEW_REPORTS_PAGE,
    }) ||
      isProjectAdmin)
      ? [
          {
            label: (
              <Link to={ROUTES.REPORTS} className="d-flex align-center">
                {MODULES.REPORTS}
              </Link>
            ),
            key: ROUTES.REPORTS,
          },
        ]
      : []),
    ...(hasInspectionAccess &&
    (CanPerform({
      action: ALLOWED_ACTION_KEYS.VIEW_TODO_PAGE,
    }) ||
      isProjectAdmin ||
      isInspector)
      ? [
          {
            label: (
              <Link to={`${ROUTES.TODO}/eqc`} className="d-flex align-center">
                {MODULES.TODO}
                {data?.getUserEqcApprovalLogs?.total +
                  instructionData?.userInstructionTodoList?.total >
                  0 && (
                  <Avatar className="todo-count" size={25}>
                    {data?.getUserEqcApprovalLogs?.total +
                      instructionData?.userInstructionTodoList?.total}
                  </Avatar>
                )}
              </Link>
            ),
            key: ROUTES.TODO,
          },
        ]
      : []),
  ];

  return (
    <Header>
      <div className="d-flex mobile-header justify-between align-center width-percent-100 ">
        <div className="d-flex align-center">
          <Dropdown
            menu={{
              items,
              selectedKeys: [
                pathname.startsWith(ROUTES.stage)
                  ? ROUTES.TODO
                  : `/${pathname.split('/')[1]}`,
              ],
            }}
            trigger={['click']}
            getPopupContainer={() =>
              // eslint-disable-next-line no-undef
              document.querySelector('#nav-bar-dropdown')
            }
            onOpenChange={(value) => setVisible(value)}
            open={visible}
          >
            {visible ? (
              <CloseIcon height={34} width={34} className="mr-10" />
            ) : (
              <MenuBar height={34} width={34} className="mr-10" />
            )}
          </Dropdown>
          <Link to={ROUTES.MAIN}>
            <div className="logo">
              <img
                src={
                  userData?.getLoggedInUser?.tenantUser?.tenant?.logo ||
                  alternateLogo
                }
                alt="logo"
                className="header-logo"
              />
            </div>
          </Link>
        </div>
        <div className="header-notification ">
          <UserProfile userData={userData} />
        </div>
      </div>
      <div id="nav-bar-dropdown" />
    </Header>
  );
};
export default MobileHeader;
