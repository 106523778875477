import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_AGENCY_EQC_APPROVAL_LOGS = gql`
  query getAgencyEqcApprovalLogs($filter: EqcApprovalLogInput!) {
    getAgencyEqcApprovalLogs(filter: $filter) {
      total
      data {
        id
        updatedAt
        approvalStatus
        tenant {
          id
          organizationName
        }
        approvalDetails
        eqc {
          id
          eqcName
          projectEqcTypeName
          project {
            id
            name
          }
        }
        eqcStageHistory {
          id
          name
          status
          eqcStageId
        }
        approvalSender {
          id
          name
          email
        }
        internalApprover {
          id
          project {
            id
            name
          }
          user {
            id
            name
          }
        }
        eqcStage {
          id
          name
          status
          eqcStageApprovals {
            id
            internalApproverId
            externalApproverId
            internalApproverName
            externalApproverName
          }
        }
        approvalUrl
      }
    }
  }
`;

export const AGENCY_EQC_LIST = gql`
  query agencyEqcList($filter: EqcFilter) {
    agencyEqcList(filter: $filter) {
      total
      data {
        id
        updatedAt
        eqcName
        eqcStageIndex
        eqcStageCount
        projectEqcTypeName
        modifier {
          id
          name
        }
        project {
          id
          name
          timeZone
        }
        eqcStages {
          id
          name
          status
          internalApproverName
          externalApproverName
          eqcStageApprovals {
            id
            internalApproverId
            externalApproverId
            internalApproverName
            externalApproverName
          }
        }
        eqcStageHistories {
          id
          name
          status
          eqcStageId
          isRfiStage
          levelNumber
          isOverdue
          inspectorReminderDate
          levelName
          projectEqcTypeStageApprovals {
            id
            internalApproverId
            externalApproverId
            levelName
            levelNumber
            internalApproverName
            externalApproverName
          }
          eqcApprovalLogs {
            id
            approvalStatus
            approvalUrl
          }
          previousEqcApprovalLogs {
            id
            internalApproverId
            externalApproverId
            approvalStatus
            levelName
            levelNumber
            approvedAt
            redoAt
            internalApproverName
            externalApproverName
          }
        }
      }
    }
  }
`;

export const GET_AGENCY_EQC_APPROVAL_LOGS_COUNT = gql`
  query getAgencyEqcApprovalLogs($filter: EqcApprovalLogInput!) {
    getAgencyEqcApprovalLogs(filter: $filter) {
      total
    }
  }
`;

export const GET_AGENCY_PENDING_EQC_APPROVAL_LOG_COUNT = gql`
  query getAgencyPendingEqcApprovalLogCount {
    getAgencyPendingEqcApprovalLogCount {
      total
    }
  }
`;

export const GET_URLS = gql`
  query getSignedPutUrls($data: [SignedUrlDataInput]) {
    getSignedPutUrls(data: $data) {
      keys
      signedUrls
    }
  }
`;

export const GET_DETAILS = gql`
  query getInstructionForUser($id: ID!, $token: String) {
    getInstructionForUser(id: $id, token: $token) {
      id
      name
      description
      tags
      closingAudio
      closingRemark
      closingPhoto
      tenantId
      createdOn
      type
      status
      respondedOn
      closedOn
      reminderDate
      uuid
      projectId
      project {
        timeZone
      }
      instructionAgencyContacts {
        contact {
          name
          phoneNo
        }
      }
      closer {
        id
        name
      }
      responder {
        id
        name
      }
      projectAgency {
        id
        agency {
          id
          name
          type
        }
      }
      tenant {
        id
        organizationName
        instructionAssetLimit
        featureApprovalRequestConfig {
          instructionAssetLimitRequestSent
        }
      }
      creator {
        id
        name
      }
      description
      descriptionAudio
      descriptionPhoto
      instructionIssueHistories {
        id
        status
        photos
        audios
        projectId
        remark
        revision
        latitude
        longitude
        projectAgencyId
        createdAt
        rejector {
          id
          name
        }
        responder {
          id
          name
        }
        creator {
          id
          name
          email
        }
      }
      instructionHistory {
        id
        modifier {
          id
          name
        }
        createdAt
        afterJson
        beforeJson
      }
      instructionRenameLogs {
        id
        newName
        oldName
        user {
          id
          name
        }
        updatedAt
      }
    }
  }
`;

export const GET_INSTRUCTION_LIST = gql`
  query contactInstructionList($filter: InstructionFilter!) {
    contactInstructionList(filter: $filter) {
      total
      instructionActionableCount
      data {
        id
        name
        tags
        type
        status
        reminderDate
        createdAt
        isOverdue
        respondedOn
        updatedAt
        project {
          id
          name
          timeZone
        }
        responder {
          id
          name
        }
        modifier {
          id
          name
        }
        rejector {
          id
          name
        }
        tenant {
          id
          organizationName
        }
      }
    }
  }
`;

export const TENANT_DROPDOWN = gql`
  query contactTenantDropdownList($filter: ContactFilter) {
    contactTenantDropdownList(filter: $filter) {
      total
      data {
        id
        organizationName
      }
    }
  }
`;
export const PROJECT_DROPDOWN = gql`
  query contactProjectDropdownList($filter: ContactFilter!) {
    contactProjectDropdownList(filter: $filter) {
      total
      data {
        id
        name
      }
    }
  }
`;
export const TYPE_DROPDOWN = gql`
  query instructionTypeList {
    instructionTypeList
  }
`;

export const INSTRUCTION_OTHER_TYPE_LIST = gql`
  query instructionOtherTypeList($filter: InstructionFilter!) {
    instructionOtherTypeList(filter: $filter) {
      total
      data
    }
  }
`;

export const INSTRUCTION_TAG_LIST = gql`
  query instructionTagList($filter: InstructionFilter!) {
    instructionTagList(filter: $filter) {
      total
      data
    }
  }
`;
