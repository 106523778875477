import { useQuery } from '@apollo/client';
import { Avatar, Card, Tabs } from 'antd';
import clsx from 'clsx';
import React from 'react';
import { useMedia } from 'react-use';
import { BREAKPOINTS, ROUTES, TAB_KEYS } from '../../common/constants';
import useRouter from '../../common/useRouter';
import TodoList from './components/TodoList';
import {
  GET_INSTRUCTION_LIST,
  GET_USER_EQC_APPROVAL_LOGS_COUNT,
} from './graphql/Queries';
import IssuesList from './issues/IssuesList';

const initialTodoFilter = {
  skip: 0,
  limit: 10,
  sortBy: { field: 'updatedAt', order: 'DESC' },
  isRfi: false,
};
const initialProjectFilter = {
  skip: 0,
  limit: 10,
  sortBy: { field: 'createdAt', order: 'DESC' },
};

const Todo = () => {
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const {
    navigate,
    params: { tab },
  } = useRouter();
  const { data } = useQuery(GET_USER_EQC_APPROVAL_LOGS_COUNT, {
    variables: { filter: initialTodoFilter },
    fetchPolicy: 'network-only',
  });
  const { data: instructionData } = useQuery(GET_INSTRUCTION_LIST, {
    variables: { filter: initialProjectFilter },
    fetchPolicy: 'network-only',
  });
  const items = [
    {
      key: TAB_KEYS.EQC,
      label: (
        <div className="d-flex align-center">
          Approvals
          {data?.getUserEqcApprovalLogs?.total > 0 && (
            <Avatar className="todo-count" size={25}>
              {data?.getUserEqcApprovalLogs?.total}
            </Avatar>
          )}
        </div>
      ),
      children: (
        <Card>
          <div className="header d-flex align-center justify-between mb-10">
            {isDesktopViewport && <h1>Pending Approvals</h1>}
            <div
              className={clsx(
                'users-header-buttons d-flex',
                !isDesktopViewport && 'width-percent-100',
              )}
            >
              <div
                id="search-component"
                className={clsx('mr-5', !isDesktopViewport && 'flex-grow-1')}
              />
              <div id="add-button" />
              <div id="user-import-export-buttons" className="d-flex" />
            </div>
          </div>
          <TodoList />
        </Card>
      ),
    },
    {
      key: TAB_KEYS.INSTRUCTION,
      label: (
        <div className="d-flex align-center">
          To Be Respond
          {instructionData?.userInstructionTodoList?.total > 0 && (
            <Avatar className="todo-count" size={25}>
              {instructionData?.userInstructionTodoList?.total}
            </Avatar>
          )}
        </div>
      ),
      children: (
        <Card>
          <div className="project-header d-flex justify-between">
            {isDesktopViewport && <h1>Issues</h1>}
            <div
              className={clsx(
                'users-header-buttons d-flex',
                !isDesktopViewport && 'width-percent-100',
              )}
            >
              <div
                id="search-component"
                className={clsx('mr-5', !isDesktopViewport && 'flex-grow-1')}
              />
              <div id="add-button" />
              <div id="user-import-export-buttons" className="d-flex" />
            </div>
          </div>
          <IssuesList />
        </Card>
      ),
    },
  ];
  return (
    <>
      {!isDesktopViewport && (
        <>
          <div className="page-header text-primary">Pending Approvals</div>
        </>
      )}
      <div className="mobile-card-wrapper">
        <div className="project-details setup-module">
          <div className="details-content">
            <Tabs
              onTabClick={(key) => navigate(`${ROUTES.TODO}/${key}`)}
              activeKey={tab}
              destroyInactiveTabPane
              items={items}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Todo;
